import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/meta/seo';

const Data = () => (
  <Layout>
    <SEO title="Redeem offer" />
    <div className="container space-around">
      <section className="container space-around">
        <div className="text-center mb-12 lg:mb-20">
          <h2 className="text-title mb-4 md:mb-6 max-w-content mx-auto">
            <span className="text-gradient bg-splice-gradient-horizontal">
              Whoops!
            </span>{' '}
            Wrong device.
          </h2>

          <p className="text-long-lead max-w-content mx-auto">
            To download the app and redeem your offer please visit the same link
            from a mobile device!
            <br />
            <br />
            If you need further help
            <a
              className="text-accent-main"
              href="https://support.spliceapp.com/hc/en-gb/requests/new"
            >
              {' '}
              contact us
            </a>
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default Data;
